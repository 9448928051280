import React from "react"
import { Link } from "gatsby"

import "../styles/notFoundPage.sass"
import { HOME_ROUTE } from "../constants/routes"
import { Layout } from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="not-found-page">
        <div className="content">
          <h1>Извините, страница не найдена</h1>

          <Link to={HOME_ROUTE}>Вернуться на главную</Link>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
